<template>
  <div class="customerNotificationStepFour">
    <div
      v-show="showStep"
    />
    <div
      v-if="successfulSent == true"
      class="loaderWrapper"
    >
      <p class="lead m--font-success">
        Messages were successfully sent.
      </p>
    </div>
    <div
      v-else-if="successfulSent == false"
      class="loaderWrapper"
    >
      <p class="lead m--font-danger">
        Messages were not sent.
      </p>
    </div>
    <div
      v-else
      class="loaderWrapper"
    >
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{width: progress + '%'}"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ progress + '%' }}
        </div>
      </div>
      <p class="lead m--font-info">
        Sending the messages to the recipients,<br>please wait a moment.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerNotificationStepFour',
  components: {
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    contactsList: {
      type: Array,
      required: true
    },
    selectedTemplate: {
      type: Object,
      required: true
    },
    stepOneObj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showStep: false,
      successfulSent: null,
      progress: 0,
      progressInterval: null
    }
  },
  watch: {
    active (val) {
      this.showStep = val;
    }
  },
  created () {
    this.showStep = this.active;
    this.sendMessages();
  },
  beforeDestroy () {
    clearInterval(this.progressInterval);
  },
  methods: {
    async sendMessages () {
      this.progressInterval = setInterval(() => {
        this.getProgress();
      }, 1000);

      var postObj = {
        filter: this.stepOneObj,
        contacts: this.contactsList
      };
      this.axios.post(`/SmsGateway/SendCustomerNotificationMessages?templateId=${ this.selectedTemplate.id }`, postObj)
        .then(() => {
          if(this.progress == 100) {
            clearInterval(this.progressInterval);
          }
        })
        .catch(() => {
          clearInterval(this.progressInterval);
          this.successfulSent = true;
        });
    },
    getProgress () {
      this.axios.get(`/SmsGateway/GetCustomerNotificationProgress`)
        .then((response) => {
          this.progress = response.data;
          if(this.progress == 100) {
            clearInterval(this.progressInterval);
            this.successfulSent = true;
          }
        })
        .catch(() => {
          clearInterval(this.progressInterval);
        });
    }
  }
}
</script>

<style scoped>
.form-control.btn.btn-secondary {
  text-align: left;
}
.loaderWrapper {
  margin: auto;
  text-align: center;
}
.m-loader {
  width: 100%;
  display: inline-block;
}
.m-loader::before {
  width: 5rem;
  height: 5rem;
  margin-left: -2.5rem;
}
.lead.m--font-info {
  padding-top: 5rem;
}
</style>